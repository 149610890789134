.verify-body{
    width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: fixed;
  z-index: 2000000;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg stroke='%23CCC' stroke-width='0' %3E%3Crect fill='%23F5F5F5' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E");
}

.email-verification-div{
    width: 40%;
    min-height: 300px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    gap: 20px;
    svg{
        font-size: 2.5rem;
    }

    @media screen and (max-width:1000px) {
        width: 90%;
    }
    @media screen and (max-width:500px) {
        width: 90%;
        min-height: 250px;
    }

}