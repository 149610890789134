.start-container {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  position: relative;
  gap: 10px;

  .channel-view-div {
    margin-top: 20px;
    min-width: 300px;
    max-width: 25vw;
    height: 250px;
    position: sticky;
    top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    padding-inline: 20px;
    text-align: center;

    .channel-div {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .channel-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: black;
        overflow: hidden;
      }

      .channel-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: calc(100% - 60px);
        height: auto;
      }
    }
    .channel-sub-heading{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
    }
  }

  .select-promotion-option {
    overflow: hidden;
    max-width: 50vw;
    padding-inline: 20px;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    // box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    background: white;
    border-radius: 10px;
    .select-video-type {
      display: flex;
      justify-content: center;

      .promote-video-div {
        display: flex;
        justify-content: center;
        gap: 30px;
        width: 100%;
        .promote-single-video {
          width: 47%;
          height: 80px;
          background-color: whitesmoke;
          border-radius: 15px;
          padding-block: 10px;
          padding-inline: 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          box-shadow: 5px 5px 20px #6d6c6c;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            transform: scale(0.98);
            transition: 0.3s;
          }
          svg {
            font-size: 2rem;
          }
        }
      }
    }
    .bulk-view-container {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
      width: 100%;
      row-gap: 30px;

      .bulk-view {
        width: 34%;
        min-width: 200px;
        box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        border-radius: 12px;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        padding-inline: 10px;
        padding-block: 20px;
        gap: 10px;
        align-items: center;
        img {
          width: 100%;
          border-radius: 5px;
          object-fit: contain;
          mix-blend-mode: multiply;
          background: transparent;
        }

        .package-info {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          gap: 4px;
        }
      }
      p {
        font-weight: 450;
        opacity: 0.5;
      }
      h4 {
        font-weight: 600;
      }
      span {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    align-items: center;

    justify-content: center;
    .channel-view-div {
      top: -10px;
      z-index: 2;
      position: relative;
    }

    .select-promotion-option {
      max-width: 100%;
    }
    .bulk-view{
      min-width: 100% !important;
    }
    .promote-video-div{
      flex-wrap: wrap;
      button{
        width: 100%;
      }
    }
  }
}
