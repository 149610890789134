.table-container {
    margin-top: 50px;
    border-radius: 5px;
    border: 1px solid lightgray;
    background: linear-gradient(
      86deg,
      rgba(255, 255, 255, 0.2) 50.14%,
      rgba(255, 255, 255, 0.04) 113.29%
    );
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    backdrop-filter: blur(24.964454650878906px);
    width: 100%;
    min-height: 500px;
    position: relative;
    padding-bottom: 50px;
    overflow-x: auto;
    background-color: white;
  }
  .main-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    position: relative;
    background: white;
  }
  .main-table th {
    background: #041E42;
    text-align: center;
    height: 30px;
    color: white;
    font-weight: normal;
    padding-inline: 2px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .sorting-row {
    position: relative;
  }
  .sorting-option {
    position: absolute;
    background: white;
    color: black;
    right: 0px;
    margin-top: 10px;
    width: 120px;
    height: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 20;
    overflow: hidden;
    transition: 0.3s;
  }
  .sorting-option li {
    list-style: none;
    padding-block: 8px;
  }
  .sorting-option li:hover {
    background: lightgray;
  }
  .main-table tr {
    text-align: center;
    height: 50px;
    transition: 0.3s;
    font-size: 0.89rem;
  }
  .main-table tr:hover {
    background: rgb(196, 195, 195);
    transition: 0.3s;
    cursor: pointer;
  }
  .main-table th,
  td {
    border-bottom: 1px solid #ddd;
  }
  
  .user-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    background: crimson;
    margin-left: 40px;
  }
  .user-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .btn-know {
    width: 70%;
    height: 30px;
    border-radius: 5px;
    border: none;
    background: var(--btnColor);
    color: white;
    cursor: pointer;
  }
  .no-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 40%;
    }
    }
  .sorry-nothing {
    font-size: 2.5rem;
  }
  .reset-btn {
    background: var(--btnColor);
  }
  
  /* View More */
 
  

  .page-number-div{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }

  @media screen and (max-width:500px) {
    
    .no-data{
      h2{
        font-size: 1rem;
      }
    img{
      width: 100%;
    }
    }
  }