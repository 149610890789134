@import "../App.scss";

.upper-listing-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  gap: 50px;

  h1 {
    font-weight: normal;
    font-size: 3rem;
    width: 80%;
    text-align: center;
    color: black;
  }

  .add-url-steps {
    display: flex;
    gap: 20px;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    .steps {
      display: flex;
      max-width: 300px;
      align-items: center;
      text-align: center;
      flex-direction: column;
      gap: 10px;

      svg {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    h1 {
      width: 100%;
    }
    .video-link-input {
      width: 100%;
    }
  }

  @media screen and (max-width: 650px) {
    h1 {
     font-size: 2.3rem;
    }
    
  }
   @media screen and (max-width: 500px) {
    h1 {
     font-size: 2rem;
    }
    
  }
  
}

// add video url input
.add-video-url-container {
  height: 75px;
  position: relative;
  background-color: $bg_color_white;
  padding-inline: 10px;
  padding-block: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
      height: 65px;
      padding-inline: 5px;
      padding-block: 7px;
  }

  .add-video-input {
    width: 100%;
    background-color: white;
    height: 100%;
    border-radius: 10px;
    border: none;
    padding-left: 60px;
    font-size: 16px;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    transition: 0.2s;
    color: rgba(0, 0, 0, 0.5);

    &:focus {
      outline: none;
      transition: 0.2s;
    }
    &:focus::placeholder {
      padding-left: 5px;
      transition: 0.2s;
      opacity: 0.7;
    }
  }
  .yt-svg {
    position: absolute;
    left: 20px;
    font-size: 2rem;
    top: 25px;
  }

  .clickable-arrow {
    width: 10%;
    background: white;
    height: 100%;
    position: relative;
    left: -10px;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .add-video-url-container {
      min-width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .add-video-url-container{
      height: 60px;
    }
    .clickable-arrow{
      width: 18%;
      left: -7px;
      svg{
        font-size: 1.5rem;
      }
    } 
    .yt-svg{
      font-size: 1.5rem;
      top: 20px;
    }
    .add-video-input{
      padding-left:40px ;
      font-size: 0.9rem;
    }
  }

}
