.selected-videos-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding-inline: 10px;
  padding-block: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;

  .show-selected-videos-preview {
    max-width: 400px;
    background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);
    display: flex;
    align-items: flex-start;
    gap: 8px;
    overflow: hidden;
    padding-inline: 20px;
    padding-block: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    position: relative;
    img {
      width: 80px;
      height: 50px;
      border-radius: 5px;
    }
    h3 {
      font-size: 0.85rem;
      font-weight: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2; /* Display up to 2 lines */
      text-overflow: ellipsis;
      max-height: 3em; /* Adjust as needed for line height and font size */
    }
    .delete-btn{
      position: absolute;
      right: 0px;
      bottom: 0px;
      padding: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      z-index: 2;
     
      cursor: pointer;
      svg{
        font-size: 1.3rem;
        color: red;
      }
    }
  }

  @media screen and (max-width:800px) {
    
    .show-selected-videos-preview{
      max-width: 100%
    }
  }
}

