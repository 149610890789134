.show-videos-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5000000000;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;

  .linked-video-header{
    position: fixed;
    left: 50%;
    top: 20%;
    width:70%;
    transform: translate(-50%,-20%);
    height: 50px;
    z-index: 3;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    padding-inline: 15px;
    justify-content: space-between;
    background: linear-gradient(90deg, #00c9ff 0%, #92fe9d 98%);
    align-items: center;
    svg{
        font-size: 2rem;
        cursor: pointer;
    }

  }

  .videos-wrpper {
    width: 70%;
    height: 500px;
    background: linear-gradient(90deg, #00c9ff 0%, #92fe9d 98%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    box-sizing: border-box;
    padding-block: 40px;
    
    .linked-video-container {
      width: 100%;
      background: white;
      display: flex;
      gap: 10px;
      align-items: center;
      padding-inline: 10px;
      padding-block: 10px;
      box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);

      .linked-video-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 1.1rem;
        overflow: hidden;
        white-space: normal;
      }

      img {
        border-radius: 5px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .linked-video-header{
        width: 80%;
    }
    .videos-wrpper {
      width: 80%;
    }
  }

  @media screen and (max-width: 500px) {
    .linked-video-header{
        width: 95%;
        top: 16%;
        h3{
            font-size: 0.95rem;
        }
    }
    .videos-wrpper {
      width: 95%;
      height: 500px !important;

      .linked-video-container {
        img {
          width: 100px;
        }
        .linked-video-title {
          font-size: 0.85rem;
          font-weight: 520;
        }
      }
    }
  }
}
