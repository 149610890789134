.hamburger-icon{
  display: none;
  
  }

.profile-div {
  position: fixed;
  right: 0px;
  border-radius: 10px 0px 0px 10px;
  top: 10px;
  background: whitesmoke;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  padding-block: 3px;
  gap: 20px;
  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;
    position: relative;
  }
  .wallet-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: white;
    padding-inline: 10px;
    padding-block: 5px;
    border-radius: 5px;
    cursor: pointer;
    svg {
      font-size: 1.7rem;
    }
  }
  .support {
    padding-inline: 5px;
    padding-block: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }

  .show-profile-option {
    width: 200px;
    overflow: hidden;
    background: white;
    position: absolute;
    top: 60px;
    right: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: 0.3s;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
  }
  z-index: 100000;
  span{
    width: 100%;
    padding-inline: 10px;
    padding-block: 2px;
    &:hover{
        background-color: #e4f1fe;
    }
  }
  @media screen and (max-width:1000px) {
    .support{
      display: none;
    }
  }

  @media screen and (max-width:500px) {
    .user-name-headline{
    display: none
   }
   .profile-img{
    display: none;
   }
  }
}

@media screen and (max-width:1000px) {
    .hamburger-icon{
      display: block;
    }
  }