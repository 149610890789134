.ticket-body {
  width: 100%;
  min-height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  gap: 30px;
  align-items: center;

  .support-image-ticket{
    width: 40vw;
    mix-blend-mode: multiply;
  }
  h1 {
    font-weight: 400;
  }

  @media screen and (max-width: 500px) {
    .support-image-ticket {
      width: 100%;
   
    }

    h1 {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}
