@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  // font-family: 'Poppins', sans-serif;
  // font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  // font-family: 'Montserrat', sans-serif;
}

$bg_color_white: #ececee;
$bg_color_light_grey: #bdbdc1;
$bg_color_primary: #050505;
$bg_color_dark_grey: #5d5d5e;
$swatch_5: #ddbabb;
$swatch_6: #aa6962;

.body-container {
  max-width: calc(1600px - 310px);
  margin: auto;
  padding-inline: 20px;
  padding-block: 20px;
  // overflow: hidden;
  // background: red;
  margin-left: 300px;
  margin-top: 70px;
  position: relative;
  min-height: calc(100vh - 80px);
}
body {
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

p {
  opacity: 0.8;
}
h1,
h2,
h3,
h5 {
  opacity: 0.8;
}
.btn-primary {
  color: white;
  font-size: 14px;
  padding-inline: 15px;
  padding-block: 8px;
  background: $bg_color_primary;
  border: none;
  border-radius: 8px;
  transition: all 0.3s;

  cursor: pointer;
  &:hover {
    // transform: translateY(5px);
    background: #323232;
  }
}

.btn-secondary {
  color: white;
  font-size: 14px;
  padding-inline: 15px;
  padding-block: 8px;
  background: #c3195d;
  border: none;
  border-radius: 10px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: #df7b7b;
  }
}
.error {
  color: red;
  font-size: 12px;
}
.nodata-image {
  width: 40%;
}


@media screen and (max-width:1000px) {
  .body-container{
    margin-left: 0;
    margin-inline: auto;
  }
}

@media screen and (max-width:500px) {
  .body-container{
   padding-inline: 10px;
  }
  h2{
    font-size: 1.5rem;
  }
  p{
    font-size: 1rem;
  }
}