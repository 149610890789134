.make-payment-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  min-height: 100vh;
  position: fixed;
  z-index: 2000000;
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  .payment-selected-channel {
    width: 30vw;
    background: white;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-inline: 20px;
    padding-block: 20px;
    border-radius: 5px;
    gap: 10px;

    .campaign-id {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
      padding-block: 5px;
    }

    .assured-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 6px;
      background: whitesmoke;
      padding-block: 5px;
      padding-block: 5px;
      border-radius: 5px;
    }
  }
  .make-payment-container {
    width: 40vw;
    background: white;
    padding-inline: 20px;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    border-radius: 5px;

    .payment-container-form {
      display: flex;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .make-payment-input-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 5px;
      }
      .dot-border {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
      }
    }
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    height: 100vh;
    padding-inline: 10px;
    flex-direction: column;
    gap: 10px;
   position: static;

    .payment-selected-channel {
      width: 100%;
    }
    .make-payment-container {
      width: 100%;
    }
  }
}
