.add-fund-container {
  width: 100%;
  height: 200px;
  background: #f5f5f7;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  padding-block: 20px;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;

  .wallet-balance {
    background: white;
    height: 100%;
    border-radius: 5px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .add-amount {
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .amount-option {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .btn-add-fund-option {
      min-width: 140px;
    }
  }
  .btn-add-money {
    padding-inline: 10px;
    border: none;
    min-width: 120px;
    background: #c3195d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    padding-block: 10px;
  }

  @media screen and (max-width: 500px) {
  flex-direction: column;
  gap: 10px;
  height: auto;
  .add-amount{
    width: 100%;
    text-align: center;
    .amount-option{
      flex-wrap: wrap;
      row-gap: 4px;
      justify-content: center;
      gap: 10px;

    }
  }
  .wallet-balance{
    width: 100%;
    padding-block: 10px;
  }
  }
}
