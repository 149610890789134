.center-affiliate{
    display: flex;
    gap: 10px;
    overflow: hidden;

    .affiliate-upper-div{
        display: flex;
        width: 50%;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        text-align: left;
        align-items:center;
        background: whitesmoke;
        padding-inline: 30px;
        padding-block: 20px;
        border-radius: 20px;
        overflow: hidden;
        height: 80vh;
        box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
     

        h1{
            font-size: 2.3rem;
        }
        .affilate-program-img{
            width: 400px;
            mix-blend-mode: multiply;
        }
        }


        .affiliate-process-container{
            display: flex;
            flex-direction: column;
            width: 50%;
            align-items: center;
            gap: 20px;
            padding-inline: 10px;
            padding-block: 10px;
            border-radius: 20px;
            
            .affiliate-card-1{
                width: 100%;
                height: 300px;
                display: flex;
                border-radius: 10px;
                background: #F5F5F7;
                flex-direction: column;
                padding-block: 10px;
                padding-inline:20px;
                justify-content: center;
                align-items: center;
                gap: 20px;
                text-align: center;
                border-radius: 20px;
                box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
                overflow: hidden;

                img{
                    height:250px;
                    mix-blend-mode: multiply;
                }

                h3{
                    margin-top: -30px;
                }

            }
            .affiliate-card{
                width: 50%;
                height: 300px;
                display: flex;
                border-radius: 10px;
                gap: 10px;
                background: #F5F5F7;
                // background: beige;
                padding-block: 10px;
                padding-inline:20px;
                border-radius: 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                text-align: center;
                box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
                img{
                    height: 150px;
                    mix-blend-mode: multiply;
                }
            }

            .right-partition-affiliate{
                display: flex;
                gap: 15px;
            }
        }

    @media screen and (max-width:1300px) {
        .affiliate-upper-div{
            .affilate-program-img{
                width: 100%;
            }
        }
        .affiliate-process-container{
            .affiliate-card{
                height: 270px;
                img{
                height: 150px;
                }
            }
        }
    }

    @media screen and (max-width:900px) {
        flex-direction: column;
        .affiliate-upper-div{
            height: 50vh;
            width: 100%;
            flex-direction: row;
            align-items: center;

            .affilate-program-img{
                height: 300px;
            }
        }
        .affiliate-process-container{
            flex-direction: row;
            width: 100%;
            .affiliate-card{
                height: 300px;
            }
        }
        
    }

    @media screen and (max-width:700px) {

        .affiliate-upper-div{
            img{
                height: 300px;
            }
        }
         .affiliate-process-container{
            flex-direction: column;
            width: 100%;
            .affiliate-card{
                height: 300px;
            }
        }
    }

    @media screen and (max-width:600px) {
        .affiliate-upper-div{
            flex-direction: column;
            flex-direction: column-reverse;
            padding-inline: 10px;
            padding-block: 10px;
            gap: 4px;
            h1{
                font-size: 1.2rem;
            }
            .affilate-program-img{
                height: 250px;
            }
            button{
                margin-top: -10px;
            }
        }
        .affiliate-process-container{
            flex-direction: column;
            width: 100%;
            .affiliate-card{
                width: 100%;
            }
           .right-partition-affiliate{
            flex-direction: column;
            width: 100%;
           }
        }
    }

    @media screen and (max-width:400px) {
       
        .affiliate-upper-div{
            gap: 4px;
            flex-direction: column-reverse;
            button{
                margin-top: -10px;
            }
            .affilate-program-img{
                height: 250px;
            }
        }
    }
}



