.package-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 50px;
   margin-top: 70px;
}
.package-card{
    max-width: 500px;
    height: 200px;
    display: flex;
    border-radius: 10px;
    background: #F5F5F7;
    padding-block: 10px;
    padding-inline:20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    
    .package-info{
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

