.campaign-video {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 60px);
  max-width: calc(1440px - 310px);
  margin: auto;
  padding-inline: 20px;
  padding-block: 20px;
  // overflow: hidden;
  // background: red;
  margin-left: 300px;
  margin-top: 100px;

  .campaign-setting {
    width: 50vw;
    background: whitesmoke;
    border-radius: 10px;
    padding-block: 20px;
    padding-inline: 40px;
    overflow: hidden;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);

    .promotion-optons-div {
      width: 100%;
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
    }

    .offer-promo-div {
      width: 100%;
      padding-inline: 10px;
      padding-block: 10px;
      background: linear-gradient(90deg, #efd5ff 0%, #515ada 100%);
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: space-between;
      margin-top: 20px;
    }

    .apply-promo-div {
      width: 100%;
      display: flex;
      gap: 20px;
      position: relative;
      margin-top: 50px;
      align-items: center;
    }

    .enter-amount-for-campaign {
      width: 100%;
      display: flex;
      gap: 20px;
      position: relative;
      justify-content: space-between;
      align-items: center;

      .amout-input {
        min-height: 50px;
        border: none;
        border-radius: 8px;
        padding-right: 20px;
        font-size: 16px;
        padding-left: 15px;
        border: 0.5px solid rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
        &:focus {
          outline: none;
          transition: 0.2s;
        }
        &:focus::placeholder {
          padding-left: 5px;
          transition: 0.2s;
          opacity: 0.7;
        }
      }
      svg {
        font-size: 1.4rem;
        position: absolute;
        right: 10px;
        color: rgba(0, 0, 0, 0.7);
        background: white;
      }
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      padding-inline: 10px;
      .enter-amount-for-campaign {
        flex-direction: column;
       align-items: flex-start;
       input{
        width: 100%;
       }
      }
    }
    .promotion-optons-div {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      row-gap: 8px;
    }
    h2 {
      font-size: 1.3rem;
    }
  }

  .show-yt-video-thumbnail {
    width: 300px;
    height: 40%;
    overflow: hidden;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    background-image: linear-gradient(90deg, #00C9FF 0%, #92FE9D 130%);
    padding-inline: 20px;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    border-radius: 20px;
    position: sticky;
    top: 150px;
    p {
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .count-view-div {
      width: 100%;
      background-color: white;
      min-height: 100px;
      border-radius: 20px;
      flex-direction: column;
      display: flex;
      gap: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .multiple-videos-div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 10px;

      img {
        width: 100px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }

  .promotion-link {
    width: 100%;
    margin-top: 20px;
    input {
      width: 100%;
      border: 0.5px solid rgba(0, 0, 0, 0.4);
      height: 50px;
      border-radius: 10px;
      box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    margin-left: 0;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
    .show-yt-video-thumbnail {
      align-items: center;
      top: 80px;
      z-index: 2;
      width: 100%;
      position: relative;
      top: -20px;

      img{
        width: 200px !important;
      }
      .count-view-div{
        min-height: 80px;
      }
    }
  }
}

// country list

.country-input-container {
  width: 100%;
  min-height: 50px;
  background: #f5f5f7;
  border-radius: 10px;
  padding-left: 20px;
  position: relative;
  overflow: hidden;
  padding-block: 10px;
  padding-inline: 10px;
  position: relative;
}
.country-input {
  width: 100%;
  min-height: 50px;
  background: white;
  border: none;
  border-radius: 10px;
  padding-left: 50px;
  border: 0.5px solid rgba(0, 0, 0, 0.6);
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
}
.country-input:focus {
  border: none !important;
  outline: none;
}

.country-list {
  width: 100%;
  overflow-x: auto;
  background: white;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
  border-radius: 10px;
  transition: 0.3s;
}
.country-name-div {
  width: 100%;
  padding-inline: 10px;
  padding-block: 5px;
  font-size: 14px;
  &:hover {
    color: white;
    background-color: black;
    cursor: pointer;
    transition: 0.2s;
  }
}
.span-country-name {
  padding-inline: 5px;
  padding-block: 5px;
  background-color: black;
  margin-left: 4px;
  border-radius: 5px;
  color: white;
  position: relative;
  cursor: pointer;
}
.span-country-list-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  row-gap: 10px;
}

// package video
.packgae-amount-div {
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 20px;
  height: 50px;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
}
.package-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
