.monetize-channel{
width: 100%;
min-height: 250px;
background: #ececee;
border-radius: 20px;
margin-top: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
justify-content: center;
box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);

.monetize-channel-info{
    width: 100%;
    gap: 20px;
    display: flex;
    justify-content: center;

}

}
.monetize-package{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ececee;
    align-items: center;
    padding-inline: 20px;
    padding-block: 20px;
    border-radius: 10px;
    
}
.monetize-requirement{
    width: 30%;
    height: 100px;
    border-radius: 10px;
    background: white;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
 
}
.buy-package{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
 
}