.center-profile{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-container{
    width: 80%;
    background: white;
    padding-inline: 20px;
    padding-block: 50px;
    border-radius: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);

    .user-profile-div{
        width: 100%;
        display: flex;
        flex-direction: column;

        .user-profile-input{
            width: 100%;
            height: 40px;
            border-radius: 6px;
            border: none;
            padding-left: 20px;
            border: 0.5px solid rgba(0,0,0,0.2);
            &:focus{
                box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
                outline: none;
            }
        }

    }


}