.pending-payment-campaign-button {
  padding-inline: 10px;
  padding-block: 10px;
  background: black;
  border: none;
  color: white;
  position: absolute;
  right: 60px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  top: 8px;
  z-index: 0;
  &:hover {
    background: rgb(34, 34, 34);
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    top: -30px;
    right: 12px;
  }
}

.delete-campaign-button {
  padding-inline: 10px;
  padding-block: 10px;
  top: 8px;
  background: red;
  border: none;
  color: white;
  position: absolute;
  right: 180px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  svg {
    font-size: 1.5rem;
  }
  &:hover {
    background: rgb(222, 36, 36);
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    top: -30px;
    right: 120px;
  }
}

.show-campaign-videos {
  width: 120px;
  background: black;
  font-size: 13px;
  color: white;
  padding-inline: 15px;
  padding-block: 5px;
  border: none;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}
.nodata-div-campaign {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}
.campaign-progress {
  width: 60%;
  height: 40px;
  margin-left: 2%;
  border-top-left-radius: 10px;
  background: linear-gradient(90deg, #00c9ff 0%, #92fe9d 98%);
  position: relative;
  border-top-right-radius: 10px;
  padding-block: 10px;
  padding-inline: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
  font-weight: 600;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -45px;
    width: 100px;
    height: 100%;
    background: #99fba3;
    border-top-right-radius: 10px;
    transform: skewX(50deg);
  }

  .progress {
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 2;
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    width: 95%;
    height: 60px;
    justify-content: flex-start;
    padding-inline: 10px;
    gap: 5px;
    &::before {
      content: "";
      display: none;
    }
  }
}

.campaign-info {
  width: 95%;
  background: #f5f5f7;
  height: 140px;
  position: relative;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  padding-inline: 20px;
  border-radius: 0px 10px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2%;
  justify-content: space-between;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);

  .yt-thumbnail {
    width: 120px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
  }
  .yt-video-views {
    width: 30%;
    display: flex;
    justify-content: space-between;
    .views {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: white;
      width: 45%;
      height: 100px;
      border-radius: 12px;
    }
  }
  .yt-video-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .yt-video-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 0.9rem;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }
  }
  p {
    font-size: 13px;
    font-weight: 420;
    text-transform: uppercase;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: auto;
    padding-inline: 10px;
    padding-block: 10px;
    gap: 7px;
    border-radius: 0px 0px 10px 10px;
    .yt-video-info {
      width: 100%;
      align-items: center;
      gap: 6px;
      text-align: center;
      .yt-video-title {
        width: 100%;
        -webkit-line-clamp: 1;
      }
    }
    .yt-thumbnail {
      height: 100px;
      width: 140px;
      border-radius: 5px;
      overflow: hidden;
      background: white;
      position: relative;
      padding-inline: 8px;
      padding-block: 8px;
      box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
      img {
        border-radius: 5px !important;
        height: 84px !important;
        width: 100% !important;
      }
    }
    .yt-video-views {
      width: 100%;
      gap: 10px;
      justify-content: center;
      .views {
        height: 60px;
        width: 50%;
        h3 {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.my-campaign-header {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  min-height: 100px;
  top: 80px;
  z-index: 10;
  background: white;
  padding-inline: 16px;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
  border-radius: 15px 15px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    height: 80px;
    .campaign-img {
      width: 80px !important;
      margin-left: -10px;
      display: none;
    }
    .alert-campaign {
      padding-inline: 4px;
      font-size: 0.8rem;
      width: 100%;
      border-radius: 10px;
    }
    .campaign-refresh-btn {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .nodata-div-campaign {
    h2 {
      font-size: 1.3rem;
    }
    img {
      width: 100%;
    }
  }
}
