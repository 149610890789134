.select-video-container{
    width: 90%;
    border-radius: 20px;
    // background: red;
    margin: auto;
    padding-inline: 30px;
    padding-block: 50px;

    .channel{
        width: 100%;
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        // background-color: red;
        align-items: center;
        gap: 10px;
        padding-block: 20px;
        .channel-img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: black;
            overflow: hidden;
        }
        svg{
            font-size: 1.5rem;
        }
    }
    @media screen and (max-width:500px) {
        padding-inline: 10px;
        width: 100%;
        padding-block: 10px;
        
    }
}
.show-channel-video{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: center;
    .show-video{
        padding-inline: 20px;
        padding-block: 20px;
        background: white;
        border-radius: 15px;
        display: flex;
        gap: 20px;
        width: 42%;
        position: relative;
        box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
        
        p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2; /* Display up to 2 lines */
            text-overflow: ellipsis;
            max-height: 3em; /* Adjust as needed for line height and font size */
          }
          
    
        .add-video{
            position: absolute;
            right: 0px;
            bottom: -10px;
           svg{
            font-size: 2rem;
            cursor: pointer;
           }
        }
    
    }
    
@media screen and (max-width:500px) {
    .show-video{
        width: 100%;
    }
}
}
