.upper-monetize-div {
  width: 100%;
  padding-block: 20px;
  padding-inline: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  border-radius: 10px;
  gap: 40px;
  box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
  .monetize-image {
    width: 40%;
    overflow: hidden;
    max-height: 300px;
    border-radius: 10px;
    img{
        width: 100%;
        object-fit: contain;
    }
  }
  .monetize-offer-div {
    width: 50%;
    padding-inline: 20px;
    padding-left: 40px;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 5px;
    .monetize-offer-div {
      width: 100%;
      padding-inline: 10px;
    }
    .monetize-image{
        width: 100%;
    }
    
  }
}
@media screen and (max-width:500px) {
    .faq-container{
        padding-inline: 10px;
        background-color: red;
    }
}
