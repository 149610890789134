.modal-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  top: 0px;
  position: fixed;
  z-index: 2000000;
  background: rgba(0,0,0,0.8);
  
  .modal-content {
    width: 30%;
    height: 240px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-inline: 20px;
    gap: 20px;
    z-index: 1;
  }

  @media screen and (max-width:500px) {
    .modal-content{
      width: 90%;
    }
    
  }
}
