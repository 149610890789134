@import "../App.scss";
.sidebar-container {
  width: 280px;
  z-index: 100000;
  min-height: calc(100vh - 100px);
  overflow: hidden;
  position: fixed;
  left: 20px;
  top: 70px;
  background: $bg_color_primary;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $bg_color_white;
  align-items: flex-start;
  padding-inline: 30px;
  padding-block: 20px;
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.5);

  .sidebar-links{
    width: 100%;
    margin-top: 20px;
  }
  .link-div {
    width: 100%;
    height: 38px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    cursor: pointer;
    transition: all 0.3s;
    color: white;

    svg {
      font-size: 1.5rem;
      color: white;
    }
    &:hover {
      color: #8dc6ff;
      transform: translateX(10px);
    }
  }
  .link-div-active {
    width: 100%;
    height: 38px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    cursor: pointer;
    color: #8dc6ff;
    transition: all 0.3s;
    font-weight: 500;
  }
  svg {
    font-size: 1.5rem;
    color: #8dc6ff;
  }

  @media screen and (max-width:1000px) {
  
    .profile-username{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
    }
  }
  @media screen and (max-width:500px) {
    width: 100% !important;
    border-radius: 24px 24px 0px 0px;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    body{
      overflow: hidden;
    }
.sidebar-links{
  margin-top: 5px;
}
  }
}
