@media screen and (max-width:500px) {
    .mobile-user-trans-div{
        padding-inline: 10px;
        padding-block: 10px;
        width: 100%;
        background: whitesmoke;
        border-radius: 10px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;
       


        .user-info-transaction{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 5px;
            h3{
                font-size: 0.85rem;
                font-weight: 520;
            }
            p{
                font-size: 0.8rem;
            }
        }
        
    }
}