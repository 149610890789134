.payment-spends-header{
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    min-height: 100px;
    top: 80px;
    z-index: 10;
    background: white;
    padding-inline: 16px;
    box-shadow: 0 3px 16px rgba(110, 125, 177, 0.28);
    border-radius: 10px 10px 2px 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    @media screen and (max-width:500px) {
        justify-content: center;
        .payment-spend-refresh-btn{
            display: none;
        }
    }
}


