.channel-list-menu{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    

    img{
        width: 30px;
        border-radius: 50%;
        height: 30px;
    }
    &:hover{
        color: red;
        cursor: pointer;
    }
}

.deafault-img{
    width: 30px;
        border-radius: 50%;
        height: 30px; 
}